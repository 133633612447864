// Code generated by codegen. DO NOT EDIT!
//
// Use 'yarn codegen' to regenerate this file based on schema and
// file defined operations.

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * ISO-3166 2 letter country codes.
   * E.g. "ES" for Spain
   */
  CountryCode: { input: string; output: string };
  /**
   * ISO-3166-2 Country subdivision codes
   * E.g. "ES-C"	A Coruña, Spain
   */
  CountrySubdivisionCode: { input: string; output: string };
  /** ISO 8601 Date represented in "yyyy-mm-dd" format. */
  Date: { input: string; output: string };
  /** DateTime is an RFC-3339 encoded timestamp. */
  DateTime: { input: string; output: string };
  /**
   * String representing an email address as defined in the [RFC 5322 section 3.4.1](https://datatracker.ietf.org/doc/html/rfc5322#section-3.4.1).
   * E.g.: "user@company.com"
   */
  EmailAddress: { input: string; output: string };
  /**
   * Language codes as defined by ISO 639-1
   * E.g. "es-ES" for Spanish
   */
  LanguageCode: { input: string; output: string };
  /**
   * Localized text. The best matching locale based on Accept-Language will be used
   * and plain string will be returned.
   */
  LocalizedText: { input: string; output: string };
  /**
   * Monetary value with amount in smallest currency unit and currency
   * using ISO-4217 represented as JSON object.
   * E.g.: `{"amount": 150, "currency": "EUR"}` represents 1.50 EUR.
   */
  Money: {
    input: { amount: number; currency: string };
    output: { amount: number; currency: string };
  };
  /**
   * String representing a phone number as defined in the [E.164](https://en.wikipedia.org/wiki/E.164) standard.
   * E.g.: "+12025550123"
   */
  PhoneNumber: { input: string; output: string };
  /**
   * Represents an RFC 3986 and RFC 3987-compliant URI string.
   * E.g. "https://wwww.staylibere.com
   */
  URL: { input: string; output: string };
};

/** Account is the union of all accountable types. */
export type Account = {
  /** Account email. */
  emailAddress: Maybe<Scalars["EmailAddress"]["output"]>;
  /** Unique identifier of the account. */
  id: Scalars["ID"]["output"];
};

/** Monetary amount with tax lines included. */
export type Amount = {
  __typename?: "Amount";
  /** Total gross value including all tax lines. */
  grossValue: Scalars["Money"]["output"];
};

/** Monetary amount with tax lines included. */
export type AmountV2 = {
  __typename?: "AmountV2";
  /** Total gross value including all tax lines. */
  gross: Scalars["Money"]["output"];
};

export type Asset = {
  __typename?: "Asset";
  /** Asset address. */
  address: AssetAddress;
  /** Asset display name */
  displayName: Scalars["String"]["output"];
  /** Asset identifier. */
  id: Scalars["ID"]["output"];
  /** Asset's featured image */
  image: Maybe<Image>;
  reviews: Maybe<ReviewConnection>;
  spaceCategories: Maybe<Array<SpaceCategory>>;
  /** Asset timezone */
  timezone: Scalars["String"]["output"];
  /** Asset type */
  type: AssetType;
};

export type AssetAddress = {
  __typename?: "AssetAddress";
  city: Scalars["String"]["output"];
  countryCode: Scalars["String"]["output"];
  line: Scalars["String"]["output"];
  postalCode: Scalars["String"]["output"];
  province: Scalars["String"]["output"];
};

export enum AssetType {
  Aparthotel = "APARTHOTEL",
  Apartment = "APARTMENT",
  Hostel = "HOSTEL",
  Hotel = "HOTEL",
  Unspecified = "UNSPECIFIED",
}

export type AvailablePaymentMethod =
  | AvailablePaymentMethodNewCard
  | AvailablePaymentMethodWireTransfer;

export type AvailablePaymentMethodNewCard = {
  __typename?: "AvailablePaymentMethodNewCard";
  id: Scalars["String"]["output"];
};

export type AvailablePaymentMethodWireTransfer = {
  __typename?: "AvailablePaymentMethodWireTransfer";
  id: Scalars["String"]["output"];
};

export type AvailableRate = {
  __typename?: "AvailableRate";
  /** Under some reservation details, the cancellation policy for this rate. */
  cancellationPolicy: Maybe<ReservationCancellationPolicy>;
  /** Under some reservation details, the payment policy for this rate. */
  paymentPolicy: Maybe<ReservationPaymentPolicy>;
  /** Rate identifier. */
  rate: Rate;
  /** Total price for the given rate. */
  total: Amount;
};

export type AvailableRateConnection = {
  __typename?: "AvailableRateConnection";
  /** List of available rates. */
  nodes: Maybe<Array<AvailableRateV2>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AvailableRateV2 = {
  __typename?: "AvailableRateV2";
  /** Under some reservation details, the cancellation policy for this rate. */
  cancellationPolicy: Maybe<ReservationCancellationPolicy>;
  /** Returns true if the rate is visible thanks to the provided voucher code. */
  enabledByVoucherCode: Maybe<Scalars["Boolean"]["output"]>;
  /** Rate identifier. */
  id: Scalars["ID"]["output"];
  /** Rate name. */
  name: Maybe<Scalars["LocalizedText"]["output"]>;
  /** Average per night price for the stay for the public rate. */
  originalPerNightPrice: Maybe<Scalars["Money"]["output"]>;
  /** Total price for the stay for the public rate. */
  originalPrice: Maybe<Scalars["Money"]["output"]>;
  /** Under some reservation details, the payment policy for this rate. */
  paymentPolicy: Maybe<ReservationPaymentPolicy>;
  /** Average per night price for the stay given rate. */
  perNightPrice: Scalars["Money"]["output"];
  /** Total price for the stay given rate. */
  price: AmountV2;
  /** Name of the promotion, if any promotion is applied */
  promoName: Maybe<Scalars["LocalizedText"]["output"]>;
};

export type CancellationDeadline = {
  __typename?: "CancellationDeadline";
  /** Date when the penalty starts applying for cancellation. */
  deadline: Scalars["DateTime"]["output"];
  /** Amount charged as penalty. */
  penalty: Scalars["Money"]["output"];
  /** Type of penalty. */
  penaltyType: CancellationPenaltyType;
};

export type CancellationPenaltyFirstNNights = {
  __typename?: "CancellationPenaltyFirstNNights";
  /** Number of nights to be charged. */
  firstNNights: Scalars["Int"]["output"];
};

export type CancellationPenaltyPercentage = {
  __typename?: "CancellationPenaltyPercentage";
  /** Fixed percentage of the total reservation amount. */
  percentage: Scalars["Int"]["output"];
};

export type CancellationPenaltyType =
  | CancellationPenaltyFirstNNights
  | CancellationPenaltyPercentage;

export type CategoryBillingSummary = {
  __typename?: "CategoryBillingSummary";
  /** The quantity of units in this category. */
  quantity: Scalars["Int"]["output"];
  /** The space category associated with the billing summary. */
  spaceCategory: SpaceCategory;
  /** The total amount for this space category. */
  totalAmount: Amount;
  /** The amount for a single unit of this category. */
  unitAmount: Amount;
};

export type ChargesBillingSummary = {
  __typename?: "ChargesBillingSummary";
  /** The total amount of penalization. */
  totalAmount: Amount;
};

export type Company = Account & {
  __typename?: "Company";
  /** Company country code. */
  countryCode: Maybe<Scalars["CountryCode"]["output"]>;
  /** Email of the company. */
  emailAddress: Maybe<Scalars["EmailAddress"]["output"]>;
  /** Full address of the company. */
  fullAddress: Maybe<Scalars["String"]["output"]>;
  /** Unique identifier of the company. */
  id: Scalars["ID"]["output"];
  /** Public name for the company. */
  name: Maybe<Scalars["String"]["output"]>;
  /** Phone of the company. */
  phoneNumber: Maybe<Scalars["PhoneNumber"]["output"]>;
  /** Province of the recipient. */
  province: Maybe<Scalars["CountrySubdivisionCode"]["output"]>;
  /** Company vat identification number. */
  vatIdentificationNumber: Maybe<Scalars["String"]["output"]>;
};

export type CompensationsBillingSummary = {
  __typename?: "CompensationsBillingSummary";
  /** The total amount of compensation. */
  totalAmount: Amount;
};

export type CreatedReservation = {
  __typename?: "CreatedReservation";
  /** Reservation reference. */
  reference: Scalars["String"]["output"];
  /** Reservation identifier. */
  reservation: Reservation;
};

export type DepositsBillingSummary = {
  __typename?: "DepositsBillingSummary";
  /** The total amount of deposit. */
  totalAmount: Amount;
};

export type Email = {
  __typename?: "Email";
  address: Scalars["String"]["output"];
};

export type EntryForm = {
  __typename?: "EntryForm";
  addressCity: Maybe<Scalars["String"]["output"]>;
  addressCountryCode: Maybe<Scalars["CountryCode"]["output"]>;
  addressLine: Maybe<Scalars["String"]["output"]>;
  addressPostalCode: Maybe<Scalars["String"]["output"]>;
  addressProvinceCode: Maybe<Scalars["CountrySubdivisionCode"]["output"]>;
  birthDate: Maybe<Scalars["Date"]["output"]>;
  documentExpirationDate: Maybe<Scalars["Date"]["output"]>;
  documentIssueDate: Maybe<Scalars["Date"]["output"]>;
  documentNumber: Maybe<Scalars["String"]["output"]>;
  documentType: Maybe<EntryFormDocumentType>;
  emailAddress: Maybe<Scalars["EmailAddress"]["output"]>;
  firstName: Maybe<Scalars["String"]["output"]>;
  gender: Maybe<EntryFormGender>;
  id: Scalars["ID"]["output"];
  isCompletable: Scalars["Boolean"]["output"];
  isCompleted: Scalars["Boolean"]["output"];
  isRequired: Scalars["Boolean"]["output"];
  kinship: Maybe<EntryFormKinship>;
  lastName: Maybe<Scalars["String"]["output"]>;
  nationalityCode: Maybe<Scalars["CountryCode"]["output"]>;
  nationalityProvince: Maybe<Scalars["CountrySubdivisionCode"]["output"]>;
  phoneNumber: Maybe<Scalars["PhoneNumber"]["output"]>;
  secondLastName: Maybe<Scalars["String"]["output"]>;
};

export enum EntryFormDocumentType {
  IdentityCard = "IDENTITY_CARD",
  IdentityCardForeigner = "IDENTITY_CARD_FOREIGNER",
  Passport = "PASSPORT",
  Unspecified = "UNSPECIFIED",
  Visa = "VISA",
}

export enum EntryFormGender {
  Female = "FEMALE",
  Male = "MALE",
  Unspecified = "UNSPECIFIED",
}

export enum EntryFormKinship {
  Parent = "PARENT",
  Tutor = "TUTOR",
  Unspecified = "UNSPECIFIED",
}

export type EntryFormSaveEntryFormAlreadyExists = {
  __typename?: "EntryFormSaveEntryFormAlreadyExists";
  message: Scalars["String"]["output"];
};

export type EntryFormSaveError = EntryFormSaveEntryFormAlreadyExists;

export type EntryFormSaveInput = {
  birthDate?: InputMaybe<Scalars["Date"]["input"]>;
  documentExpirationDate?: InputMaybe<Scalars["Date"]["input"]>;
  documentIssueDate?: InputMaybe<Scalars["Date"]["input"]>;
  documentNumber?: InputMaybe<Scalars["String"]["input"]>;
  documentType?: InputMaybe<EntryFormDocumentType>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<EntryFormGender>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  nationalityCode?: InputMaybe<Scalars["CountryCode"]["input"]>;
  nationalityProvince?: InputMaybe<Scalars["CountrySubdivisionCode"]["input"]>;
  reservationId: Scalars["ID"]["input"];
  reservationInhabitantId: Scalars["ID"]["input"];
  secondLastName?: InputMaybe<Scalars["String"]["input"]>;
  signatureSvg?: InputMaybe<Scalars["String"]["input"]>;
};

export type EntryFormSavePayload = {
  __typename?: "EntryFormSavePayload";
  entryForm: Maybe<EntryForm>;
  userErrors: Maybe<Array<EntryFormSaveError>>;
};

export enum Gender {
  /** Female. */
  Female = "FEMALE",
  /** Male. */
  Male = "MALE",
  /** Unspecified gender. */
  Unspecified = "UNSPECIFIED",
}

export type Image = {
  __typename?: "Image";
  /** The location of the image as a URL. */
  url: Scalars["URL"]["output"];
};

export type Inhabitant = Account & {
  __typename?: "Inhabitant";
  /** Date of birth of the inhabitant. */
  birthDate: Maybe<Scalars["Date"]["output"]>;
  /** Default language used to communicate with the inhabitant. */
  defaultLanguage: Maybe<Scalars["LanguageCode"]["output"]>;
  /**
   * Email of the inhabitant.
   * Deprecated: Use emailAddress instead.
   * @deprecated Use emailAddress instead.
   */
  email: Maybe<Email>;
  /** Email of the inhabitant. */
  emailAddress: Maybe<Scalars["EmailAddress"]["output"]>;
  /** The first name of the inhabitant. */
  firstName: Maybe<Scalars["String"]["output"]>;
  /** Gender of the inhabitant. */
  gender: Gender;
  /** The internal identifier of the inhabitant. */
  id: Scalars["ID"]["output"];
  /** The last name of the inhabitant. */
  lastName: Scalars["String"]["output"];
  /** Nationality code of the inhabitant. */
  nationalityCode: Scalars["CountryCode"]["output"];
  /**
   * Phone of the inhabitant.
   * @deprecated Use phoneNumber instead.
   */
  phone: Maybe<Phone>;
  /** Phone of the inhabitant. */
  phoneNumber: Maybe<Scalars["PhoneNumber"]["output"]>;
  /** Inhabitant preferences */
  preferences: InhabitantPreferences;
  /** The second last name of the inhabitant. */
  secondLastName: Maybe<Scalars["String"]["output"]>;
};

export type InhabitantPreferences = {
  __typename?: "InhabitantPreferences";
  /** Marketing communications accepted or not by the inhabitant. */
  marketingCommunicationsAccepted: Scalars["Boolean"]["output"];
};

export type InhabitantUpdateInput = {
  inhabitantId: Scalars["ID"]["input"];
  marketingCommunicationsAccepted?: InputMaybe<Scalars["Boolean"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type InhabitantUpdatePayload = {
  __typename?: "InhabitantUpdatePayload";
  inhabitant: Maybe<Inhabitant>;
};

/**
 * Represents a time interval, encoded as a Timestamp start (inclusive) and a
 * Timestamp end (exclusive).
 */
export type Interval = {
  __typename?: "Interval";
  /** Optional. Exclusive end of the interval. */
  endTime: Maybe<Scalars["DateTime"]["output"]>;
  /** Optional. Inclusive start of the interval. */
  startTime: Maybe<Scalars["DateTime"]["output"]>;
};

/**
 * Represents a time interval, encoded as a Timestamp start (inclusive) and a
 * Timestamp end (exclusive).
 */
export type IntervalInput = {
  /** Optional. Exclusive end of the interval. */
  endTime?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Optional. Inclusive start of the interval. */
  startTime?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InvalidArgumentError = {
  /** Concrete field that caused the error relative to the input, e.g.: `reservations.0.checkinDate`. */
  field: Scalars["String"]["output"];
  /** A message describing the error. */
  message: Scalars["String"]["output"];
};

export type Invite = {
  __typename?: "Invite";
  /** The email of the invite */
  email: Scalars["String"]["output"];
  /** The first name of the invite */
  firstName: Scalars["String"]["output"];
  /** The id of the invite */
  id: Scalars["ID"]["output"];
  /** The last name of the invite */
  lastName: Scalars["String"]["output"];
};

export enum InvoiceCannotRequestReason {
  /** Invoice has already been requested. */
  AlreadyRequested = "ALREADY_REQUESTED",
  /** The viewer is not authorized to request invoice. */
  Unauthorized = "UNAUTHORIZED",
}

export type LoyaltyProgram = {
  __typename?: "LoyaltyProgram";
  discountPercentage: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  promoCode: Scalars["String"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  entryFormSave: Maybe<EntryFormSavePayload>;
  inhabitantUpdate: Maybe<InhabitantUpdatePayload>;
  /** Add payment to purchase. */
  paymentMethodSetup: PaymentMethodSetupPayload;
  /** Simple ping endpoint. Just for testing. */
  ping: Scalars["String"]["output"];
  /** Add payment to purchase. */
  purchaseAddPayment: PurchaseAddPaymentPayload;
  /** Request an invoice for a purchase. */
  purchaseRequestInvoice: Maybe<PurchaseRequestInvoicePayload>;
  /** Check in a reservation. */
  reservationCheckin: Maybe<ReservationCheckinPayload>;
  /** Checkout a reservation. */
  reservationCheckout: ReservationCheckoutPayload;
  reservationInhabitantClear: Maybe<ReservationInhabitantClearPayload>;
  reservationInhabitantSaveEntryForm: Maybe<ReservationInhabitantSaveEntryFormPayload>;
  /** Cancels the specified reservations within a purchase. */
  reservationsCancel: Maybe<ReservationsCancelPayload>;
  /** Create reservations. */
  reservationsCreate: ReservationsCreatePayload;
};

export type MutationEntryFormSaveArgs = {
  input: EntryFormSaveInput;
};

export type MutationInhabitantUpdateArgs = {
  input: InhabitantUpdateInput;
};

export type MutationPaymentMethodSetupArgs = {
  input: PaymentMethodSetupInput;
};

export type MutationPurchaseAddPaymentArgs = {
  input: PurchaseAddPaymentInput;
};

export type MutationPurchaseRequestInvoiceArgs = {
  input: PurchaseRequestInvoiceInput;
};

export type MutationReservationCheckinArgs = {
  input: ReservationCheckinInput;
};

export type MutationReservationCheckoutArgs = {
  input: ReservationCheckoutInput;
};

export type MutationReservationInhabitantClearArgs = {
  input: ReservationInhabitantClearInput;
};

export type MutationReservationInhabitantSaveEntryFormArgs = {
  input: ReservationInhabitantSaveEntryFormInput;
};

export type MutationReservationsCancelArgs = {
  input: ReservationsCancelInput;
};

export type MutationReservationsCreateArgs = {
  input: ReservationsCreateInput;
};

/** Represents the ordering direction of a list */
export enum OrderDirection {
  /** Specifies an ascending order for a given `orderBy` argument. */
  Asc = "ASC",
  /** Specifies a descending order for a given `orderBy` argument. */
  Desc = "DESC",
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars["String"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
};

export type PaymentDeadline = {
  __typename?: "PaymentDeadline";
  /** Payment amount. */
  amount: Scalars["Money"]["output"];
  /** Date when the payment must be made. */
  deadline: Scalars["DateTime"]["output"];
};

export type PaymentIntent = {
  __typename?: "PaymentIntent";
  /** True indicates that the payment is a pre-authorization */
  authorizeOnly: Scalars["Boolean"]["output"];
  /** The amount to be charged. */
  pendingAmount: Scalars["Money"]["output"];
  /** Platform of the payment intent */
  platform: PaymentIntentPlatform;
};

export type PaymentIntentPlatform = PaymentIntentPlatformStripe;

export type PaymentIntentPlatformStripe = {
  __typename?: "PaymentIntentPlatformStripe";
  piClientSecret: Scalars["String"]["output"];
  publishableKey: Scalars["String"]["output"];
};

export type PaymentMethod = {
  __typename?: "PaymentMethod";
  /** Payment method identifier. */
  id: Scalars["ID"]["output"];
  /** Payment method type. */
  type: Maybe<PaymentMethodType>;
};

export type PaymentMethodCard = {
  __typename?: "PaymentMethodCard";
  /** Card brand */
  brand: PaymentMethodCardBrand;
  /** Expiration month */
  expMonth: Scalars["Int"]["output"];
  /** Expiration year */
  expYear: Scalars["Int"]["output"];
  /** Last 4 digits */
  last4Digits: Scalars["String"]["output"];
};

export enum PaymentMethodCardBrand {
  Amex = "AMEX",
  Diners = "DINERS",
  Discover = "DISCOVER",
  Jcb = "JCB",
  Mastercard = "MASTERCARD",
  Unionpay = "UNIONPAY",
  Unknown = "UNKNOWN",
  Unspecified = "UNSPECIFIED",
  Visa = "VISA",
}

export type PaymentMethodSetupInput = {
  /** Asset identifier. */
  assetId: Scalars["ID"]["input"];
};

export type PaymentMethodSetupInvalidArgumentError = UserError & {
  __typename?: "PaymentMethodSetupInvalidArgumentError";
  /** Human-readable message describing the error. */
  message: Scalars["String"]["output"];
};

export type PaymentMethodSetupPayload = {
  __typename?: "PaymentMethodSetupPayload";
  /** Resultant StripeSetupIntent. */
  stripeSetupIntent: Maybe<StripeSetupIntent>;
  /** Errors that occurred. */
  userErrors: Maybe<Array<PaymentMethodSetupUserError>>;
};

export type PaymentMethodSetupUserError =
  PaymentMethodSetupInvalidArgumentError;

export type PaymentMethodType = PaymentMethodCard;

export type Phone = {
  __typename?: "Phone";
  number: Scalars["String"]["output"];
};

export type ProductBillingSummary = {
  __typename?: "ProductBillingSummary";
  /** The product associated with the billing summary. */
  product: StoreProduct;
  /** The total amount for this product. */
  totalAmount: Amount;
};

export type Purchase = {
  __typename?: "Purchase";
  /** Asset of the purchase */
  asset: Maybe<Asset>;
  /** Provides a breakdown of the purchase price. */
  billingSummary: Maybe<PurchaseBillingSummary>;
  /**
   * Latest checkoutTime of the reservations
   * @deprecated use lastCheckoutTime instead
   */
  endTime: Scalars["DateTime"]["output"];
  /** Earliest checkinTime of the reservations */
  firstCheckinTime: Scalars["DateTime"]["output"];
  /** Unique identifier for the purchase. */
  id: Scalars["ID"]["output"];
  /** Latest checkoutTime of the reservations */
  lastCheckoutTime: Scalars["DateTime"]["output"];
  /**
   * Owner of the purchase. Typically the requester of the purchase.
   * It will be null if the viewer is not the owner of the purchase.
   */
  owner: Maybe<Account>;
  /** List of reservations in the purchase. */
  reservations: Maybe<ReservationConnection>;
  /**
   * Earliest checkinTime of the reservations
   * @deprecated use firstCheckinTime instead
   */
  startTime: Scalars["DateTime"]["output"];
  /** Status of the purchase */
  status: PurchaseStatus;
  /** Returns true if the viewer can request an invoice for this purchase. */
  viewerCanRequestInvoice: Scalars["Boolean"]["output"];
  /** Returns reasons why the viewer cannot request an invoice for this purchase. */
  viewerCannotRequestInvoiceReasons: Maybe<Array<InvoiceCannotRequestReason>>;
  /**
   * True if the viewer is the main inhabitant in the purchase.
   * @deprecated Use viewerIsOwner instead
   */
  viewerIsBooker: Scalars["Boolean"]["output"];
  /**
   * True if the viewer is the main inhabitant in the purchase.
   * @deprecated Use Reservation.viewerIsStayOwner instead
   */
  viewerIsMainInhabitant: Scalars["Boolean"]["output"];
  /** True if the viewer is the owner of the purchase. */
  viewerIsOwner: Scalars["Boolean"]["output"];
};

export type PurchaseReservationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PurchaseAddPaymentError =
  | PurchaseAddPaymentNoPendingBalanceError
  | PurchaseAddPaymentProcessingError
  | PurchaseAddPaymentUnauthorizedError;

export type PurchaseAddPaymentInput = {
  /** Purchase identifier. */
  purchaseId: Scalars["ID"]["input"];
};

export type PurchaseAddPaymentNoPendingBalanceError = UserError & {
  __typename?: "PurchaseAddPaymentNoPendingBalanceError";
  /** There is no pending payment. */
  message: Scalars["String"]["output"];
};

export type PurchaseAddPaymentPayload = {
  __typename?: "PurchaseAddPaymentPayload";
  /** Resultant PaymentIntent */
  paymentIntent: Maybe<PaymentIntent>;
  /** Charge errors. */
  userErrors: Maybe<Array<PurchaseAddPaymentError>>;
};

export type PurchaseAddPaymentProcessingError = UserError & {
  __typename?: "PurchaseAddPaymentProcessingError";
  /** The payment failed during processing. */
  message: Scalars["String"]["output"];
};

export type PurchaseAddPaymentUnauthorizedError = UserError & {
  __typename?: "PurchaseAddPaymentUnauthorizedError";
  /** Unauthorized to add payment. */
  message: Scalars["String"]["output"];
};

export type PurchaseBalance = {
  __typename?: "PurchaseBalance";
  /** The amount that has already been paid. */
  paidAmount: Amount;
  /** The remaining amount to be paid. */
  pendingAmount: Amount;
  /** The total purchase amount. */
  totalAmount: Amount;
};

export type PurchaseBillingSummary = {
  __typename?: "PurchaseBillingSummary";
  /** Summary of the purchase balance, including total, paid, and pending amounts. */
  balance: PurchaseBalance;
  /** Charges, if applicable. */
  chargesBillingSummary: Maybe<ChargesBillingSummary>;
  /** Compensations, if applicable. */
  compensationsBillingSummary: Maybe<CompensationsBillingSummary>;
  /** Deposit, if applicable. */
  depositsBillingSummary: Maybe<DepositsBillingSummary>;
  /** Indicates whether the reservation has pending payment. */
  hasPendingPayment: Scalars["Boolean"]["output"];
  /** Indicates whether the reservation is collected by the property. */
  isCollectedByProperty: Scalars["Boolean"]["output"];
  /** Total amounts categorized by space. */
  totalsByCategory: Maybe<Array<CategoryBillingSummary>>;
  /** Total amounts categorized by product. */
  totalsByProduct: Maybe<Array<ProductBillingSummary>>;
  /** Tourist taxes, if applicable. */
  touristTaxesSummary: Maybe<TouristTaxesBillingSummary>;
};

export type PurchaseConnection = {
  __typename?: "PurchaseConnection";
  /** A list of edges. */
  nodes: Array<Purchase>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export enum PurchaseOrderField {
  /** Order purchases by the creation time */
  CreatedAt = "CREATED_AT",
  /** Order purchases by the first checkin time of its reservations */
  FirstCheckinTime = "FIRST_CHECKIN_TIME",
  /** Order purchases by the last checkout time of its reservations */
  LastCheckoutTime = "LAST_CHECKOUT_TIME",
}

export type PurchaseRequestInvoiceError =
  PurchaseRequestInvoiceInvalidArgumentError;

export type PurchaseRequestInvoiceInput = {
  /** Email address to send the invoice to. */
  emailAddress: Scalars["EmailAddress"]["input"];
  /** Optional notes for the request. */
  notes?: InputMaybe<Scalars["String"]["input"]>;
  /** ID of the purchase for which the invoice is requested. */
  purchaseId: Scalars["ID"]["input"];
  /** Recipient info for the invoice. */
  recipientInfo: RecipientInfoInput;
  /** Request is for another person. */
  requestForDifferentPerson?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PurchaseRequestInvoiceInvalidArgumentError = InvalidArgumentError &
  UserError & {
    __typename?: "PurchaseRequestInvoiceInvalidArgumentError";
    /** Concrete field that caused the error relative to the input, e.g.: `reservations.0.checkinDate`. */
    field: Scalars["String"]["output"];
    /** A message describing the error. */
    message: Scalars["String"]["output"];
  };

export type PurchaseRequestInvoicePayload = {
  __typename?: "PurchaseRequestInvoicePayload";
  /** User errors that prevented the invoice from being requested. */
  userErrors: Array<PurchaseRequestInvoiceError>;
};

export enum PurchaseStatus {
  /** When all reservations in the purchase are cancelled */
  Cancelled = "CANCELLED",
  /** When some reservation is CHECKED_IN. Prioritized over CONFIRMED */
  CheckedIn = "CHECKED_IN",
  /** When some reservation is CHECKED_OUT. Prioritized over CHECKED_IN */
  CheckedOut = "CHECKED_OUT",
  /** When some reservation is confirmed */
  Confirmed = "CONFIRMED",
  /** All reservations in the Purchase are in PENDING status */
  Pending = "PENDING",
}

export type PurchasesFilter = {
  /** Filter purchases by asset id. */
  assetIdIn?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  /** Filter purchases with last checkout time within the given interval */
  lastCheckoutTimeWithin?: InputMaybe<IntervalInput>;
};

export type PurchasesOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field to order purchases by. */
  field: PurchaseOrderField;
};

export type Query = {
  __typename?: "Query";
  /** Retrieve a asset by its unique identifier. */
  asset: Maybe<Asset>;
  loyaltyProgram: Maybe<LoyaltyProgram>;
  /** Simple ping endpoint. Just for testing. */
  ping: Scalars["String"]["output"];
  /** Retrieve a purchase by its unique identifier. */
  purchase: Maybe<Purchase>;
  /** List Purchases of the logged in inhabitant */
  purchases: Maybe<PurchaseConnection>;
  /** Retrieve a reservation by its unique identifier. */
  reservation: Maybe<Reservation>;
  /** Get the list of viewer reservations. */
  reservations: Maybe<ReservationConnection>;
  /** Obtain a quotation for reservations based on provided details. */
  reservationsQuotation: Maybe<ReservationsQuotationPayload>;
  spaceCategory: Maybe<SpaceCategory>;
  /**
   * Viewer of the application based on session.
   * @deprecated Use viewerV2 instead.
   */
  viewer: Maybe<Inhabitant>;
  /** Viewer of the application based on session. */
  viewerV2: Maybe<Viewer>;
};

export type QueryAssetArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryPurchaseArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryPurchasesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<PurchasesFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<PurchasesOrder>>;
};

export type QueryReservationArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryReservationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<ReservationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReservationsOrder>>;
};

export type QueryReservationsQuotationArgs = {
  input: ReservationsQuotationInput;
};

export type QuerySpaceCategoryArgs = {
  id: Scalars["ID"]["input"];
};

export type Rate = {
  __typename?: "Rate";
  /** Rate unique identifier. */
  id: Scalars["ID"]["output"];
  /** Rate name. */
  name: Scalars["LocalizedText"]["output"];
};

export type RecipientInfoInput = {
  /** Address of the recipient. */
  address?: InputMaybe<Scalars["String"]["input"]>;
  /** Company name of the recipient. */
  companyName?: InputMaybe<Scalars["String"]["input"]>;
  /** Country code of the recipient. */
  countryCode?: InputMaybe<Scalars["CountryCode"]["input"]>;
  /** Name of the recipient. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Province of the recipient. */
  province?: InputMaybe<Scalars["CountrySubdivisionCode"]["input"]>;
  /** VAT identification number of the recipient. */
  vatIdentificationNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type Reservation = {
  __typename?: "Reservation";
  /** Number of inhabitants in the reservation classified as adult. */
  adultCount: Scalars["Int"]["output"];
  /** The asset to which the reservation belongs */
  asset: Asset;
  /** Assigned space */
  assignedSpace: Maybe<Space>;
  /** Reservation billing summary. */
  billingSummary: Maybe<ReservationBillingSummary>;
  /** Reservation cancellation policy. */
  cancellationPolicy: Maybe<ReservationCancellationPolicy>;
  /** The check-in time of the reservation. */
  checkinTime: Scalars["DateTime"]["output"];
  /** The check-out time of the reservation. */
  checkoutTime: Scalars["DateTime"]["output"];
  /** Number of inhabitants in the reservation classified as child. */
  childCount: Scalars["Int"]["output"];
  /** The internal confirmation number of the reservation. */
  confirmationNumber: Scalars["String"]["output"];
  /** Creation time of the reservation */
  createdAt: Scalars["DateTime"]["output"];
  /** Payment method used as guarantee in the reservation. */
  guaranteePaymentMethod: Maybe<PaymentMethod>;
  /** The unique identifier of the reservation. */
  id: Scalars["ID"]["output"];
  /** Reservation inhabitants */
  inhabitants: Array<ReservationInhabitant>;
  /** True when reservation group is ready to checkin */
  isGroupReady: Scalars["Boolean"]["output"];
  key: Maybe<ReservationKey>;
  /** The third-party OTA confirmation number of the reservation. */
  otaConfirmationNumber: Maybe<Scalars["String"]["output"]>;
  /** Reservation payment policy. */
  paymentPolicy: Maybe<ReservationPaymentPolicy>;
  /** The purchase associated with the reservation. */
  purchase: Purchase;
  /** The rate of the reservation */
  rate: Rate;
  /**
   * Release time is the deadline to confirm pending reservations.
   * If reservation is already confirmed, this will be null.
   */
  releaseTime: Maybe<Scalars["DateTime"]["output"]>;
  /** Requested space category */
  requestedCategory: SpaceCategory;
  /** The status of the reservation. */
  status: ReservationStatus;
  storeOrders: Maybe<StoreOrderConnection>;
  /** Travel agency through which the reservation has been made */
  travelAgency: Maybe<TravelAgency>;
  /** Checks if the current viewer can add products to the reservation. */
  viewerCanAddStoreProducts: Scalars["Boolean"]["output"];
  /** Checks if the viewer is able to cancel the reservation. */
  viewerCanCancel: Scalars["Boolean"]["output"];
  /** Checks if the current viewer can check in the reservation. */
  viewerCanCheckin: Scalars["Boolean"]["output"];
  /** Checks if the current viewer can checkout the reservation. */
  viewerCanCheckout: Scalars["Boolean"]["output"];
  /** Reasons why the current viewer cannot check in the reservation. */
  viewerCannotCheckinReasons: Maybe<Array<ReservationCannotCheckinReason>>;
  /** Reasons why the current viewer cannot checkout the reservation. */
  viewerCannotCheckoutReasons: Maybe<Array<ReservationCannotCheckoutReason>>;
  /**
   * Returns reservation inhabitant for the current viewer.
   * If the viewer is not an inhabitant, it will return null.
   */
  viewerInhabitant: Maybe<ReservationInhabitant>;
  /** True if the inhabitant is included in the reservation */
  viewerIsReservationInhabitant: Scalars["Boolean"]["output"];
  /** True if the reservation is owned by the viewer */
  viewerIsStayOwner: Scalars["Boolean"]["output"];
};

export type ReservationStoreOrdersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ReservationActionRequireContactError = UserError & {
  __typename?: "ReservationActionRequireContactError";
  /** Error message. */
  message: Scalars["String"]["output"];
};

export type ReservationBillingSummary = {
  __typename?: "ReservationBillingSummary";
  /** Stay amount */
  stayAmount: Amount;
};

export type ReservationCancellationPolicy = {
  __typename?: "ReservationCancellationPolicy";
  /** Current cancellation deadline. */
  currentDeadline: CancellationDeadline;
  /** List of applicable cancellation fees and their dates. */
  deadlines: Array<CancellationDeadline>;
  /**
   * Date limit for free cancellation.
   * If it is null, no free cancellation is allowed.
   */
  freeCancellationUntil: Maybe<Scalars["DateTime"]["output"]>;
};

export enum ReservationCancellationReason {
  ChangedDates = "CHANGED_DATES",
  ChangedPlans = "CHANGED_PLANS",
  FoundBetterLocation = "FOUND_BETTER_LOCATION",
  FoundBetterPrice = "FOUND_BETTER_PRICE",
  Other = "OTHER",
  Unspecified = "UNSPECIFIED",
}

export enum ReservationCannotCheckinReason {
  /** The reservation is not in a state that allows checkout. */
  InvalidReservationStatus = "INVALID_RESERVATION_STATUS",
  /** There is a pending entry form for the reservation. */
  PendingEntryForm = "PENDING_ENTRY_FORM",
  /** There is a pending payment for the reservation. */
  PendingPayment = "PENDING_PAYMENT",
  /** The reservation has space assigned, but the space is not yet ready. */
  SpaceNotReady = "SPACE_NOT_READY",
  /** The reservation checkin date has not been reached. */
  TooEarlyToCheckin = "TOO_EARLY_TO_CHECKIN",
  /** The viewer is not authorized to checkout the reservation. */
  Unauthorized = "UNAUTHORIZED",
}

export enum ReservationCannotCheckoutReason {
  /** The reservation is not in a state that allows checkout. */
  InvalidReservationStatus = "INVALID_RESERVATION_STATUS",
  /** There is a pending payment for the reservation. */
  PendingPayment = "PENDING_PAYMENT",
  /**
   * The reservation checkout date has not been reached.
   * In case user wants to checkout before the checkout date, reservation dates
   * must be modified before checkout.
   */
  TooEarlyToCheckout = "TOO_EARLY_TO_CHECKOUT",
  /** The viewer is not authorized to checkout the reservation. */
  Unauthorized = "UNAUTHORIZED",
}

export type ReservationCheckinError =
  | ReservationCheckinPendingEntryFormError
  | ReservationCheckinSpaceNotReadyError
  | ReservationCheckinTooEarlyError
  | ReservationInvalidStatusError
  | ReservationPendingPaymentError;

export type ReservationCheckinInput = {
  /** Reservation id to be checked in. */
  reservationId: Scalars["ID"]["input"];
};

export type ReservationCheckinPayload = {
  __typename?: "ReservationCheckinPayload";
  /** Resultant checked in reservation. */
  checkedInReservation: Maybe<Reservation>;
  /** User errors that prevented the checkin from being completed. */
  userErrors: Array<ReservationCheckinError>;
};

export type ReservationCheckinPendingEntryFormError = UserError & {
  __typename?: "ReservationCheckinPendingEntryFormError";
  /** Error message. */
  message: Scalars["String"]["output"];
};

export type ReservationCheckinSpaceNotReadyError = UserError & {
  __typename?: "ReservationCheckinSpaceNotReadyError";
  /** Error message. */
  message: Scalars["String"]["output"];
};

export type ReservationCheckinTooEarlyError = UserError & {
  __typename?: "ReservationCheckinTooEarlyError";
  /** Error message. */
  message: Scalars["String"]["output"];
};

export type ReservationCheckoutError =
  | ReservationActionRequireContactError
  | ReservationCheckoutTooEarlyError
  | ReservationInvalidStatusError
  | ReservationPendingPaymentError;

export type ReservationCheckoutInput = {
  /** Reservation id to be checked out. */
  reservationId: Scalars["ID"]["input"];
};

export type ReservationCheckoutPayload = {
  __typename?: "ReservationCheckoutPayload";
  /** Resultant checked out reservation. */
  checkedOutReservation: Maybe<Reservation>;
  /** User errors that prevented the checkout from being completed. */
  userErrors: Array<ReservationCheckoutError>;
};

export type ReservationCheckoutTooEarlyError = UserError & {
  __typename?: "ReservationCheckoutTooEarlyError";
  /** Error message. */
  message: Scalars["String"]["output"];
};

export type ReservationConnection = {
  __typename?: "ReservationConnection";
  /** A list of edges. */
  nodes: Array<Reservation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ReservationFilter = {
  /** Filter reservations with stay within the given interval */
  stayWithin?: InputMaybe<IntervalInput>;
};

export type ReservationInhabitant = {
  __typename?: "ReservationInhabitant";
  /** The age category of the reservation inhabitant */
  ageCategory: ReservationInhabitantAgeCategory;
  /** The entry form. If it is null, is because something failed fetching it */
  entryForm: Maybe<EntryForm>;
  /** The id of the reservation inhabitant */
  id: Scalars["ID"]["output"];
  /** The inhabitant. It can be null when no inhabitant is related */
  inhabitant: Maybe<Inhabitant>;
  /** The inhabitant. It can be null when no invite is related */
  invite: Maybe<Invite>;
};

export enum ReservationInhabitantAgeCategory {
  /** The reservation inhabitant is for an adult */
  Adult = "ADULT",
  /** The reservation inhabitant is for a child */
  Child = "CHILD",
}

export type ReservationInhabitantClearInput = {
  reservationId: Scalars["ID"]["input"];
  reservationInhabitantId: Scalars["ID"]["input"];
};

export type ReservationInhabitantClearPayload = {
  __typename?: "ReservationInhabitantClearPayload";
  reservation: Maybe<Reservation>;
};

export enum ReservationInhabitantEntryFormDocumentType {
  IdentityCard = "IDENTITY_CARD",
  IdentityCardForeigner = "IDENTITY_CARD_FOREIGNER",
  Passport = "PASSPORT",
  Unspecified = "UNSPECIFIED",
  Visa = "VISA",
}

export enum ReservationInhabitantEntryFormGender {
  Female = "FEMALE",
  Male = "MALE",
  Unspecified = "UNSPECIFIED",
}

export enum ReservationInhabitantEntryFormKinship {
  Parent = "PARENT",
  Tutor = "TUTOR",
  Unspecified = "UNSPECIFIED",
}

export type ReservationInhabitantSaveEntryFormError =
  | EntryFormSaveEntryFormAlreadyExists
  | ReservationInhabitantSaveEntryFormGroupCompleteError;

export type ReservationInhabitantSaveEntryFormGroupCompleteError = UserError & {
  __typename?: "ReservationInhabitantSaveEntryFormGroupCompleteError";
  message: Scalars["String"]["output"];
};

export type ReservationInhabitantSaveEntryFormInput = {
  addressCity?: InputMaybe<Scalars["String"]["input"]>;
  addressCountryCode?: InputMaybe<Scalars["CountryCode"]["input"]>;
  addressLine?: InputMaybe<Scalars["String"]["input"]>;
  addressPostalCode?: InputMaybe<Scalars["String"]["input"]>;
  addressProvinceCode?: InputMaybe<Scalars["CountrySubdivisionCode"]["input"]>;
  birthDate?: InputMaybe<Scalars["Date"]["input"]>;
  documentExpirationDate?: InputMaybe<Scalars["Date"]["input"]>;
  documentIssueDate?: InputMaybe<Scalars["Date"]["input"]>;
  documentNumber?: InputMaybe<Scalars["String"]["input"]>;
  documentType?: InputMaybe<ReservationInhabitantEntryFormDocumentType>;
  emailAddress?: InputMaybe<Scalars["EmailAddress"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<ReservationInhabitantEntryFormGender>;
  kinship?: InputMaybe<ReservationInhabitantEntryFormKinship>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  nationalityCode?: InputMaybe<Scalars["CountryCode"]["input"]>;
  nationalityProvince?: InputMaybe<Scalars["CountrySubdivisionCode"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
  reservationId: Scalars["ID"]["input"];
  reservationInhabitantId: Scalars["ID"]["input"];
  secondLastName?: InputMaybe<Scalars["String"]["input"]>;
  signatureSvg?: InputMaybe<Scalars["String"]["input"]>;
};

export type ReservationInhabitantSaveEntryFormPayload = {
  __typename?: "ReservationInhabitantSaveEntryFormPayload";
  entryForm: Maybe<EntryForm>;
  /** Errors that occurred saving the entry form. */
  userErrors: Maybe<Array<ReservationInhabitantSaveEntryFormError>>;
};

export type ReservationInput = {
  /** Adults count. */
  adultCount: Scalars["Int"]["input"];
  /** Checkin date. */
  checkinDate: Scalars["Date"]["input"];
  /** Checkout date. */
  checkoutDate: Scalars["Date"]["input"];
  /** Children count. */
  childCount: Scalars["Int"]["input"];
  /** Rate identifier. */
  rateId: Scalars["ID"]["input"];
  /** Reference string to identify reservations in response. */
  reference?: InputMaybe<Scalars["String"]["input"]>;
  /** Requested space category identifier. */
  requestedCategoryId: Scalars["ID"]["input"];
  /** Requested products for the reservation. */
  requestedProducts?: InputMaybe<Array<ReservationProductDetails>>;
};

export type ReservationInvalidStatusError = UserError & {
  __typename?: "ReservationInvalidStatusError";
  /** Error message. */
  message: Scalars["String"]["output"];
};

export type ReservationKey = {
  __typename?: "ReservationKey";
  pinCode: Maybe<Scalars["String"]["output"]>;
};

export enum ReservationOrderField {
  /** Order reservations by the checkin time */
  CheckinTime = "CHECKIN_TIME",
  /** Order reservations by the checkout time */
  CheckoutTime = "CHECKOUT_TIME",
  /** Order reservations by the creation time */
  CreatedAt = "CREATED_AT",
}

export type ReservationPaymentPolicy = {
  __typename?: "ReservationPaymentPolicy";
  /**
   * Current payment deadline.
   * Whether a payment must be paid now and the amount.
   */
  currentDeadline: Maybe<PaymentDeadline>;
  /** List of payment amounts and their dates. */
  deadlines: Array<PaymentDeadline>;
  /**
   * Date limit to make the payment.
   * If it is null, reservation must be paid now.
   */
  noPaymentRequiredUntil: Maybe<Scalars["DateTime"]["output"]>;
};

export type ReservationPendingPaymentError = UserError & {
  __typename?: "ReservationPendingPaymentError";
  /** Error message. */
  message: Scalars["String"]["output"];
};

export type ReservationPriceBreakdown = {
  __typename?: "ReservationPriceBreakdown";
  products: Array<ReservationProductPricing>;
  stay: ReservationStayPricing;
  total: Amount;
  touristTaxes: Array<ReservationTouristTaxPricing>;
};

export type ReservationProductDetails = {
  /** Product identifier */
  productId: Scalars["ID"]["input"];
  /** Requested quantity. If product is per person, the final quantity will be multiplied by people count. */
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ReservationProductPricing = {
  __typename?: "ReservationProductPricing";
  product: StoreProduct;
  quantity: Scalars["Int"]["output"];
  total: Amount;
};

export type ReservationQuote = {
  __typename?: "ReservationQuote";
  /** Adult count. */
  adultCount: Scalars["Int"]["output"];
  /** Asset for the reservation. */
  asset: Maybe<Asset>;
  /** Products available for the given conditions. */
  availableProducts: Maybe<Array<Maybe<StoreProduct>>>;
  /** Rates available for the given conditions. */
  availableRates: Maybe<Array<AvailableRate>>;
  /** Available units left of the requested space category and date interval. Quoted units are not computed. */
  availableUnits: Maybe<Scalars["Int"]["output"]>;
  /** Under some reservation details, the cancellation policy for this rate. */
  cancellationPolicy: Maybe<ReservationCancellationPolicy>;
  /** Checkin time. */
  checkinTime: Scalars["DateTime"]["output"];
  /** Checkout time. */
  checkoutTime: Scalars["DateTime"]["output"];
  /** Child count. */
  childCount: Scalars["Int"]["output"];
  /** Under some reservation details, the payment policy for this rate. */
  paymentPolicy: Maybe<ReservationPaymentPolicy>;
  /** Pricing breakdown. */
  priceBreakdown: ReservationPriceBreakdown;
  /** Rate identifier. */
  rate: Maybe<Rate>;
  /** Identifier used to find the reservation in the response. */
  reference: Scalars["String"]["output"];
  /** Requested space category for the reservation. */
  spaceCategory: Maybe<SpaceCategory>;
};

export type ReservationQuoteInput = {
  /** Adult count. */
  adultCount: Scalars["Int"]["input"];
  /** Checkin date. */
  checkinDate: Scalars["Date"]["input"];
  /** Checkout date. */
  checkoutDate: Scalars["Date"]["input"];
  /** Child count. */
  childCount: Scalars["Int"]["input"];
  /** Products requested for the reservation. */
  products?: InputMaybe<Array<ReservationProductDetails>>;
  /** Selected rate. Cheapest available rate will be selected if empty. */
  rateId?: InputMaybe<Scalars["String"]["input"]>;
  /** Identifier used to find the reservation in the response. */
  reference: Scalars["String"]["input"];
  /** Requested category identifier. */
  requestedCategoryId: Scalars["ID"]["input"];
};

export enum ReservationStatus {
  /**
   * The reservation is cancelled.
   * This is a final state.
   */
  Cancelled = "CANCELLED",
  /** The reservation is checked in. */
  CheckedIn = "CHECKED_IN",
  /**
   * The reservation is checked out.
   * This is a final state.
   */
  CheckedOut = "CHECKED_OUT",
  /** The reservation is confirmed. */
  Confirmed = "CONFIRMED",
  /**
   * The reservation is pending confirmation.
   * A payment or confirmation may be required.
   */
  Pending = "PENDING",
}

export type ReservationStayPricing = {
  __typename?: "ReservationStayPricing";
  total: Amount;
};

export type ReservationTouristTaxPricing = {
  __typename?: "ReservationTouristTaxPricing";
  taxRate: TaxRate;
  total: Amount;
};

export type ReservationsCancelInput = {
  /** Additional notes to provide further details when the cancellation reason is set to OTHER. */
  notes?: InputMaybe<Scalars["String"]["input"]>;
  /** The unique identifier of the purchase to which the reservations belong. */
  purchaseId: Scalars["ID"]["input"];
  /** The reason for cancelling the reservations. */
  reason: ReservationCancellationReason;
  /** A list of unique identifiers for the reservations to be cancelled. */
  reservationIds: Array<Scalars["ID"]["input"]>;
};

export type ReservationsCancelPayload = {
  __typename?: "ReservationsCancelPayload";
  /** A list of reservations that were successfully cancelled. */
  cancelledReservations: Array<Reservation>;
};

export type ReservationsCreateInhabitantInput = {
  /** Date of birth of the inhabitant. */
  birthDate?: InputMaybe<Scalars["Date"]["input"]>;
  /** Default language used to communicate with the inhabitant. */
  defaultLanguage?: InputMaybe<Scalars["LanguageCode"]["input"]>;
  /** Inhabitant email. */
  emailAddress: Scalars["EmailAddress"]["input"];
  /** Inhabitant first name. */
  firstName: Scalars["String"]["input"];
  /** Inhabitant gender. */
  gender?: InputMaybe<Gender>;
  /** Inhabitant last name. */
  lastName: Scalars["String"]["input"];
  /** Allow marketing communications. */
  marketingCommunicationsAccepted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Inhabitant nationality. */
  nationalityCode?: InputMaybe<Scalars["CountryCode"]["input"]>;
  /** Inhabitant phone number. */
  phoneNumber?: InputMaybe<Scalars["PhoneNumber"]["input"]>;
  /** Inhabitant second last name. */
  secondLastName?: InputMaybe<Scalars["String"]["input"]>;
};

export type ReservationsCreateInput = {
  /** Asset identifier. */
  assetId: Scalars["ID"]["input"];
  /**
   * A unique identifier for the client performing the mutation.
   * Two requests with the same client identifier will be considered as retries of the same operation.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** Payment method that will be used as guarantee. */
  paymentMethodId?: InputMaybe<Scalars["ID"]["input"]>;
  /**
   * Purchase owner information.
   * Required if not logged in, otherwise must be omitted.
   *
   * Purchase owner is the person who is paying for the reservation.
   */
  purchaseOwnerInfo?: InputMaybe<ReservationsCreateInhabitantInput>;
  /** Reservations data. */
  reservations: Array<ReservationInput>;
  /** Special requests. Free text. */
  specialRequests?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Stay owner information.
   * Required if booking for a third party, otherwise must be omitted.
   *
   * Stay owner is the person who will stay in the asset. If omitted,
   * the purchase owner will be considered the stay owner.
   */
  stayOwnerInfo?: InputMaybe<ReservationsCreateInhabitantInput>;
  /** Voucher code to be applied. */
  voucherCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type ReservationsCreateInvalidArgumentError = InvalidArgumentError &
  UserError & {
    __typename?: "ReservationsCreateInvalidArgumentError";
    /** Concrete field that caused the error relative to the input, e.g.: `reservations.0.checkinDate`. */
    field: Scalars["String"]["output"];
    /** Human-readable message describing the error. */
    message: Scalars["String"]["output"];
  };

/** There is no availability for the requested reservation. */
export type ReservationsCreateNoAvailabilityError = UserError & {
  __typename?: "ReservationsCreateNoAvailabilityError";
  /** Human-readable message describing the error. */
  message: Scalars["String"]["output"];
};

export type ReservationsCreatePayload = {
  __typename?: "ReservationsCreatePayload";
  /** Reservations created. */
  reservations: Maybe<Array<CreatedReservation>>;
  /** Errors that occurred during the creation of reservations. */
  userErrors: Maybe<Array<ReservationsCreateUserError>>;
};

/** Selected parameters are restricted for the requested reservation. */
export type ReservationsCreateRestrictedDatesError = UserError & {
  __typename?: "ReservationsCreateRestrictedDatesError";
  /** Human-readable message describing the error. */
  message: Scalars["String"]["output"];
};

export type ReservationsCreateUserError =
  | ReservationsCreateInvalidArgumentError
  | ReservationsCreateNoAvailabilityError
  | ReservationsCreateRestrictedDatesError;

export type ReservationsOrder = {
  /**
   * The ordering direction
   * .
   */
  direction?: OrderDirection;
  /** The field to order reservations by. */
  field: ReservationOrderField;
};

export type ReservationsQuotationInput = {
  /** Asset identifier. */
  assetId: Scalars["ID"]["input"];
  /** Details of the reservations to be quoted. */
  reservations: Array<ReservationQuoteInput>;
  /** Voucher's code. */
  voucherCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type ReservationsQuotationPayload = {
  __typename?: "ReservationsQuotationPayload";
  /** Payment methods available for the given conditions. */
  availablePaymentMethods: Maybe<Array<AvailablePaymentMethod>>;
  /** Details and prices of the quoted reservations. */
  reservations: Maybe<Array<ReservationQuote>>;
  /** Total price of the quoted reservations. */
  total: Maybe<Amount>;
};

export type Review = {
  __typename?: "Review";
  /** Content of the review. */
  content: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  /** Platform where review was written. */
  platform: ReviewPlatform;
  /** Rating out of 5 */
  rating: Scalars["Float"]["output"];
  /** The person who wrote the review. */
  reviewer: Reviewer;
  /** Title of the review. */
  title: Maybe<Scalars["String"]["output"]>;
};

export type ReviewConnection = {
  __typename?: "ReviewConnection";
  /** Average rating of all reviews out of 5. */
  averageRating: Scalars["Float"]["output"];
  /** Count of edges. */
  count: Scalars["Int"]["output"];
  /** A list of edges. */
  nodes: Array<Review>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export enum ReviewPlatform {
  Airbnb = "AIRBNB",
  BookingCom = "BOOKING_COM",
  GoogleMaps = "GOOGLE_MAPS",
  Tripadvisor = "TRIPADVISOR",
  Unspecified = "UNSPECIFIED",
}

export type Reviewer = {
  __typename?: "Reviewer";
  /** Name of the reviewer. */
  name: Scalars["String"]["output"];
};

export type Space = {
  __typename?: "Space";
  /** Category of the space. */
  category: Maybe<SpaceCategory>;
  /** Floor number of the space. */
  floorNumber: Scalars["String"]["output"];
  /** Unique identifier for the space. */
  id: Scalars["ID"]["output"];
  /** Name of the space. */
  name: Scalars["String"]["output"];
};

export type SpaceCategory = {
  __typename?: "SpaceCategory";
  /** Quotes available rates for the category. */
  availableRates: Maybe<AvailableRateConnection>;
  /** Number of bathrooms inside the space */
  bathrooms: Scalars["Int"]["output"];
  /** Number of bedrooms. 0 if it's an studio */
  bedrooms: Scalars["Int"]["output"];
  /** Bed configuration  */
  beds: SpaceCategoryBeds;
  /** Space category's capacity */
  capacity: Scalars["Int"]["output"];
  /** The unique identifier for the space category. */
  id: Scalars["ID"]["output"];
  /** Space category's featured image */
  image: Maybe<Image>;
  /** Space category's images */
  images: Maybe<Array<Image>>;
  /** The name of the space category. */
  name: Scalars["LocalizedText"]["output"];
  /** Position to be used in listings */
  position: Scalars["Int"]["output"];
  /** Average space size in square meters */
  size: Scalars["Int"]["output"];
  /** Space category has terrace */
  terrace: Scalars["Boolean"]["output"];
  /** The type of the space category */
  type: SpaceCategoryType;
};

export type SpaceCategoryAvailableRatesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  checkinDate: Scalars["Date"]["input"];
  checkoutDate: Scalars["Date"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  voucherCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type SpaceCategoryBeds = {
  __typename?: "SpaceCategoryBeds";
  /** Bunk bed count */
  bunkBed: Scalars["Int"]["output"];
  /** Double bed count */
  double: Scalars["Int"]["output"];
  /** Single bed count */
  single: Scalars["Int"]["output"];
  /** Sofa bed count */
  sofaBed: Scalars["Int"]["output"];
  /** Twin bed count */
  twin: Scalars["Int"]["output"];
  /** Twin or double bed count (for categories with mixed bed type) */
  twinOrDouble: Scalars["Int"]["output"];
};

export enum SpaceCategoryType {
  Apartment = "APARTMENT",
  Bed = "BED",
  Dorm = "DORM",
  Room = "ROOM",
}

export type StoreOrder = {
  __typename?: "StoreOrder";
  /** Date and time the order was created */
  createdAt: Scalars["DateTime"]["output"];
  /** Unique identifier for the order */
  id: Scalars["ID"]["output"];
  /** Lines in the order */
  lines: Array<StoreOrderLine>;
};

export type StoreOrderConnection = {
  __typename?: "StoreOrderConnection";
  /** Count of edges */
  count: Scalars["Int"]["output"];
  /** A list of edges */
  nodes: Array<StoreOrder>;
  /** Information to aid in pagination */
  pageInfo: PageInfo;
};

export type StoreOrderLine = {
  __typename?: "StoreOrderLine";
  /** Fulfilment status of the order line */
  fulfilmentStatus: StoreOrderLineFulfilmentStatus;
  /** Unique identifier for the order line */
  id: Scalars["ID"]["output"];
  /** Product in the order line */
  product: StoreProduct;
};

export enum StoreOrderLineFulfilmentStatus {
  Cancelled = "CANCELLED",
  Completed = "COMPLETED",
  Confirmed = "CONFIRMED",
  InProgress = "IN_PROGRESS",
  OnHold = "ON_HOLD",
  Pending = "PENDING",
}

export type StoreProduct = {
  __typename?: "StoreProduct";
  /** The charging type of the product */
  chargingType: StoreProductChargingType;
  /** The delivery instructions of the product. */
  deliveryInstructions: Maybe<Scalars["LocalizedText"]["output"]>;
  /** The description of the product. */
  description: Maybe<Scalars["LocalizedText"]["output"]>;
  /** The unique identifier for the product. */
  id: Scalars["ID"]["output"];
  /** Product's featured image */
  image: Maybe<Image>;
  /** The name of the product. */
  name: Scalars["LocalizedText"]["output"];
  /** The price of the product */
  price: Amount;
  /** The price of the product before applying promotions */
  priceOld: Maybe<Amount>;
};

export enum StoreProductChargingType {
  Once = "ONCE",
  PerNight = "PER_NIGHT",
  PerPerson = "PER_PERSON",
  PerPersonPerNight = "PER_PERSON_PER_NIGHT",
}

export type StripeSetupIntent = {
  __typename?: "StripeSetupIntent";
  /** Secret used to confirm the SetupIntent on the client-side */
  clientSecret: Scalars["String"]["output"];
  /** Unique identifier for the SetupIntent */
  id: Scalars["ID"]["output"];
  /** Stripe public API key for client-side requests */
  publishableKey: Scalars["String"]["output"];
};

export type TaxRate = {
  __typename?: "TaxRate";
  code: Scalars["String"]["output"];
};

export type TouristTaxesBillingSummary = {
  __typename?: "TouristTaxesBillingSummary";
  /** The total amount of tourist taxes. */
  totalAmount: Amount;
};

export type TravelAgency = Account & {
  __typename?: "TravelAgency";
  /** Travel agency email. */
  emailAddress: Maybe<Scalars["EmailAddress"]["output"]>;
  /** Unique identifier of the travel agency. */
  id: Scalars["ID"]["output"];
  /** True if is an online travel agency */
  isOnlineTravelAgency: Scalars["Boolean"]["output"];
};

/** Validation, business logic that can be handled by the client */
export type UserError = {
  /** An error message intended to be used as debug and not shown to the user. */
  message: Scalars["String"]["output"];
};

export type Viewer = {
  __typename?: "Viewer";
  accessLink: Maybe<Scalars["URL"]["output"]>;
  /** Viewer linked account. */
  account: Maybe<Account>;
  /** Viewer email. */
  emailAddress: Maybe<Scalars["EmailAddress"]["output"]>;
  /** Unique identifier of the viewer. */
  id: Scalars["ID"]["output"];
};

export type ViewerAccessLinkArgs = {
  scopePurchaseId?: InputMaybe<Scalars["ID"]["input"]>;
  targetPath?: InputMaybe<Scalars["String"]["input"]>;
};
